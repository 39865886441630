.mapboxgl-popup-content {
  border-radius: 0.5rem;
  padding: 10px 24px;
  min-width: 200px;
  max-width: auto;
}

.mapboxgl-popup-close-button {
  height: 24px;
  width: 24px;
  margin: 0px 0px 0 0;
  font-size: 20px;
  text-align: center;
  line-height: 4px;
  /* box-shadow: 3px 3px 5px rgba(0, 0, 0, .5); */
  padding-bottom: 5px;
  top: 2px;
  right: 2px;
  background-color: #fff;
  border-radius: 6px;

}

.mapboxgl-popup-close-button:hover {
  background-color: #eee;
}

.mapboxgl-canvas {
  border-radius: 6px
}