@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1); */
  border-radius: 10px;
  background-color: rgba(0, 0, 0, .1);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;

}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1); */
  background-color: rgba(0, 0, 0, .2);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, .3);
}